/** Game Render Module
 * Called by the game loop, this module will
 * perform use the global state to re-render
 * the canvas using new data. Additionally,
 * it will call all game entities `render`
 * methods.
 */
function gameRender(scope) {

     // Load entity sprites
     var heartImage = new Image();
     heartImage.src = "images/heart.png";
 

    return function render() {

        // Setup globals
        var w = scope.viewport.width,
            h = scope.viewport.height;

        // Clear out the canvas
        scope.context.clearRect(0, 0, w, h);

        // Spit out some text
        scope.context.font = '12px Arial';
        scope.context.fillStyle = '#fff';
        //scope.context.fillText('It\'s dangerous to travel this route alone.', 5, 50);

        // If we want to show the FPS, then render it in the top right corner.
        if (scope.constants.showFps) {
            scope.context.fillStyle = '#ff0';
            scope.context.fillText(scope.loop.fps, w - 40, 20);
        }
        
        // If we want to show entities count, then render it in the top right corner.
        if (scope.constants.showEntitiesCount) {
            scope.context.fillStyle = '#ff0';
            scope.context.fillText(Object.keys(scope.state.entities).length, w - 80, 20);
        }

        // Show days count
        scope.context.fillStyle = '#ff0';
        scope.context.fillText('Day ' + (scope.state.currentDay + 1), w - 60, 90);

        // Show score
        scope.context.fillStyle = '#ff0';
        scope.context.fillText('Score', w - 60, 110);
        var minus = scope.state.score < 0;
        var scoreString = '' + (!minus ? scope.state.score : scope.state.score * -1);
        while (scoreString.length < 5) {
            scoreString = '0' + scoreString;
        }
        if (minus) {
            scoreString = '-' + scoreString;
        }
        scope.context.fillText(scoreString, w - 60, 130);

        // Show left lives
        for (var i = 0; i < scope.state.playerLifes; i++) {
            scope.context.drawImage(
                heartImage,
                w - 50,
                150 + (20 * i)
            );
        }

        // If there are entities, iterate through them and call their `render` methods
        if (scope.state.hasOwnProperty('entities')) {
            var entities = scope.state.entities;
            // Loop through entities
            for (var entity in entities) {
                // Fire off each active entities `render` method
                entities[entity].render();
            }
        }

        // Render game over if there are no lifes left
        if (scope.state.playerLifes === 0) {
            scope.context.font = h / 10 + 'px Arial';
            scope.context.fillStyle = '#FF0000';
            scope.context.fillText('GAME OVER', w / 2 - (h / 4), h / 2 - (h / 10));       
        }
    };
}

module.exports = gameRender;