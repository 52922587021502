/** Clock Module
 * Main clock entity module.
 */
function Clock(scope, x, y) {
    var clock = this;

    // Create the initial state
    clock.state = {
        size: {
            h: 0,
            w: 0
        },
        position: {
            x: x,
            y: y
        },
        second: 0,
        before: 0
    };

    // Set up any other constants

    // Clock size
    var size = 20;

    // Day long in seconds
    var dayLong = 10;

    // Draw the player on the canvas
    clock.render = function() {

        // Calculate clock arrow position
        var lineFromX = clock.state.position.x;
        var lineFromY = clock.state.position.y;
        
        var corner = clock.state.second / 360 * 2 * Math.PI;
        var x = Math.sin(corner) * size;
        var y = Math.cos(corner) * size;
        
        var lineToX = lineFromX + x;
        var lineToY = lineFromY - y;

        // Draw arrow
        scope.context.beginPath();
        scope.context.moveTo(lineFromX, lineFromY);
        scope.context.lineTo(lineToX, lineToY);
        scope.context.lineWidth = 2;
        scope.context.strokeStyle = "#FF0000";
        scope.context.stroke();
    };

    // Fired via the global update method.
    // Mutates state as needed for proper rendering next state
    clock.update = function() {
        var now = performance.now();
        
        // Check if needed seconds elapsed
        if (now - clock.state.before > 1000 / 360 * dayLong) {
            clock.state.second++;
            if (clock.state.second >= 360) {
                clock.state.second = 0;
                scope.setNightMode(!scope.state.nightMode);
            }
 
            // Save before to state
            clock.state.before = now;
        }

    };

    return clock;
}

module.exports = Clock;