module.exports = {
    getOpinion: function(percentOfChance) {
        return percentOfChance > Math.random() * 100;
    },
    makeChoice: function(choices) {
        var choicesCount = choices.length;
        var choiceIndex = Math.floor(Math.random() * choicesCount);
        if (choiceIndex === choicesCount) {
            choiceIndex = choicesCount - 1;
        }
        return choices[choiceIndex];
    },
    makeNumberChoice: function(from, to) {
        return from + Math.floor(Math.random() * (to - from));
    },
    findMovesTowardEntity: function(entityFrom, entityTo) {
        var moves = [];
        if (entityFrom.state.position.x < entityTo.state.position.x) {
            moves.push('right');
        } else {
            moves.push('left');
        }
        if (entityFrom.state.position.y < entityTo.state.position.y) {
            moves.push('down');
        } else {
            moves.push('up');
        }
        return moves;
    }
}