var keys = require('../utils/utils.keysDown.js');
var btns = require('../utils/utils.btnDown.js');
var mathHelpers = require('../utils/utils.math.js');
var botEnt = require('../entities/bot.js');

/** Player Module
 * Main player entity module.
 */
function Player(scope, x, y) {
    var player = this;

    // Create the initial state
    player.state = {
        size: {
            h: 0,
            w: 0
        },
        position: {
            x: x,
            y: y
        },
        lastDirection: 'up',
        lastShot: 0,
        moveSpeed: 1.5
    };

    // Set up any other constants

    // Load entity sprite
    var spriteImage = new Image();
    spriteImage.src = "images/human.png";

    // Sprite image contants
    var spriteSizeX = 266;
    var spriteSizeY = 148;
    var playerSizeA = 74;
    var playerSizeB = 118;

    // Zoom sprite to smaller this times 
    var zoom = 4;

    // Draw the player on the canvas
    player.render = function playerRender() {

        // Initial size and coords in sprite
        var spriteX = 0;
        var spriteY = 0;
        var spriteH = playerSizeA;
        var spriteW = playerSizeB;

        // Switch sprite based on last move
        switch (player.state.lastDirection) {
            case 'left':
                spriteX = spriteSizeX - playerSizeA;
            break;
            case 'right':
                spriteX = playerSizeB;
            break;
            case 'up':
                spriteH = playerSizeB;
                spriteW = playerSizeA;
            break;
            case 'down':
                spriteY = playerSizeA;
                spriteH = playerSizeB;
                spriteW = playerSizeA;
            break;
        };
        
        // Draw sprite, couting sprite center as position
        scope.context.drawImage(
            spriteImage,
            spriteX,
            spriteY,
            spriteH,
            spriteW,
            player.state.position.x - spriteH / zoom / 2,
            player.state.position.y - spriteW / zoom / 2,
            spriteH / zoom,
            spriteW / zoom
        );
    };

    // Fired via the global update method.
    // Mutates state as needed for proper rendering next state
    player.update = function playerUpdate() {

        var now = performance.now();

        // Check if keys are pressed, if so, update the players position.
        if (keys.isPressed.left || btns.isPressed.left) {
            player.state.lastDirection = 'left';
            player.state.position.x -= player.state.moveSpeed;
        } else

        if (keys.isPressed.right || btns.isPressed.right) {
            player.state.lastDirection = 'right';
            player.state.position.x += player.state.moveSpeed;
        } else

        if (keys.isPressed.up || btns.isPressed.up) {
            player.state.lastDirection = 'up';
            player.state.position.y -= player.state.moveSpeed;
        } else

        if (keys.isPressed.down || btns.isPressed.down) {
            player.state.lastDirection = 'down';
            player.state.position.y += player.state.moveSpeed;
        }

        if ((keys.isPressed.space || btns.isPressed.space) && player.state.lastShot + 100 < now) {
            scope.shoot(player);
            player.state.lastShot = now;
        }

        // Update entity size
        switch (player.state.lastDirection) {
            case 'left':
            case 'right':
                player.state.size.h = playerSizeB / zoom;
                player.state.size.w = playerSizeA / zoom;
            break;
            case 'up':
            case 'down':
                player.state.size.h = playerSizeA / zoom;
                player.state.size.w = playerSizeB / zoom;
            break;
        };

        // Bind the player to the boundary
        player.state.position.x = player.state.position.x.boundary(0, scope.viewport.width);
        player.state.position.y = player.state.position.y.boundary(0, scope.viewport.height);
    
        // Find collision to destroyable entity and destroy it by loosing life
        var collideEntity = scope.findCollision(player);
        if (collideEntity && collideEntity instanceof botEnt) {
            collideEntity.destroy();
            
            // Remove one life
            scope.state.playerLifes--;

            // Remove score for loosing life
            scope.state.score -= 100;
        }
    };

    return player;
}

module.exports = Player;