/** Grid Module
 * Main grid entity module.
 */
function Grid(scope, x, y) {
    var grid = this;

    // Create the initial state
    grid.state = {
        size: {
            h: 0,
            w: 0
        },
        position: {
            x: 0,
            y: 0
        }
    };

    // Set up any other constants

    // Space in pixels between lines in grid
    var lineSpace = 25;

    // Draw the player on the canvas
    grid.render = function() {

        // Draw grid
        scope.context.beginPath();

        // Vertical lines
        for (var i = 0; i < scope.viewport.width; i += lineSpace) {
            scope.context.moveTo(i, 0);
            scope.context.lineTo(i, scope.viewport.height);
        }
        
        // Horizontal lines
        for (var i = 0; i < scope.viewport.height; i += lineSpace) {
            scope.context.moveTo(0, i);
            scope.context.lineTo(scope.viewport.width, i);
        }
        scope.context.lineWidth = 1;
        scope.context.strokeStyle = '#1f1f1f';
        scope.context.stroke();
    };

    // Fired via the global update method.
    // Mutates state as needed for proper rendering next state
    grid.update = function() {
    };

    return grid;
}

module.exports = Grid;