var mathHelpers = require('../utils/utils.math.js');
var AI = require('../utils/utils.ai.js');

/** Bot Module
 * Main bot entity module.
 */
function Bot(scope, x, y) {
    var bot = this;

    // Create the initial state
    bot.state = {
        size: {
            h: 0,
            w: 0
        },
        position: {
            x: x,
            y: y
        },
        lastDirection: 'up',
        moveSpeed: 1
    };

    // Set up any other constants
    // Load entity sprite
    var spriteImage = new Image();
    spriteImage.src = "images/bot.png";

    // Sprite image contants
    var spriteSizeX = 266;
    var spriteSizeY = 148;
    var botSizeA = 74;
    var botSizeB = 118;

    // Zoom sprite to smaller this times 
    var zoom = 4;

    // Draw the player on the canvas
    bot.render = function() {

        // Initial size and coords in sprite
        var spriteX = 0;
        var spriteY = 0;
        var spriteH = botSizeA;
        var spriteW = botSizeB;

        // Switch sprite based on last move
        switch (bot.state.lastDirection) {
            case 'left':
                spriteX = spriteSizeX - botSizeA;
            break;
            case 'right':
                spriteX = botSizeB;
            break;
            case 'up':
                spriteH = botSizeB;
                spriteW = botSizeA;
            break;
            case 'down':
                spriteY = botSizeA;
                spriteH = botSizeB;
                spriteW = botSizeA;
            break;
        };
        
        // Draw sprite, couting sprite center as position
        scope.context.drawImage(
            spriteImage,
            spriteX,
            spriteY,
            spriteH,
            spriteW,
            bot.state.position.x - spriteH / zoom / 2,
            bot.state.position.y - spriteW / zoom / 2,
            spriteH / zoom,
            spriteW / zoom
        );
    };

    // Fired via the global update method.
    // Mutates state as needed for proper rendering next state
    bot.update = function() {

        // Bot AI

        // Should I change direction?
        if (AI.getOpinion(5)) {

            // Get moves towards player
            var moves = AI.findMovesTowardEntity(bot, scope.state.entities.player);

            // Add one more random move
            moves.push(AI.makeChoice(['left', 'right', 'up', 'down']));

            // Deside where to go
            bot.state.lastDirection = AI.makeChoice(moves);
        }
        
        // Update entity size
        switch (bot.state.lastDirection) {
            case 'left':
            case 'right':
                bot.state.size.h = botSizeB / zoom;
                bot.state.size.w = botSizeA / zoom;
            break;
            case 'up':
            case 'down':
                bot.state.size.h = botSizeA / zoom;
                bot.state.size.w = botSizeB / zoom;
            break;
        };

        // Move based on last desition
        switch (bot.state.lastDirection)
        {
            case 'left': 
                bot.state.position.x -= bot.state.moveSpeed;
            break;
            case 'right':
                bot.state.position.x += bot.state.moveSpeed;
            break;
            case 'up':
                bot.state.position.y -= bot.state.moveSpeed;
            break;
            case 'down':
                bot.state.position.y += bot.state.moveSpeed;
            break;
        }

        // Bind the bot to the boundary
        //bot.state.position.x = bot.state.position.x.boundary(0, scope.viewport.width);
        //bot.state.position.y = bot.state.position.y.boundary(0, scope.viewport.height);
    };

    bot.destroy = function() {
        scope.deleteEntity(bot);
    };

    return bot;
}

module.exports = Bot;