var cUtils = require('../utils/utils.canvas.js');

/** Game Update Module
 * Called by the game loop, this module will
 * perform any state calculations / updates
 * to properly render the next frame.
 */
function gameUpdate(scope) {
    return function (tFrame, elapsed) {
        var state = scope.state || {};

        // Do not update after life is over
        if (scope.state.playerLifes > 0) {

            // If there are engines, iterate through them and call their `update` methods
            if (state.hasOwnProperty('engines')) {
                var engines = state.engines;

                // Loop through engines
                for (var engine in engines) {

                    // Fire off each active engine `update` method
                    engines[engine].update();
                }
            }

            // If there are entities, iterate through them and call their `update` methods
            if (state.hasOwnProperty('entities')) {
                var entities = state.entities;

                // Loop through entities
                for (var entity in entities) {

                    // Fire off each active entities `update` method
                    entities[entity].update();
                }
            }
        }

        // After update, resize canvas size, in case window size changed
        cUtils.resizeCanvas(scope.viewport);

        return state;
    };
}

module.exports = gameUpdate;