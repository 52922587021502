var mathHelpers = require('../utils/utils.math.js');

/** Bullet Module
 * Main bullet entity module.
 */
function Bullet(scope, shooter) {
    var bullet = this;

    // Create the initial state
    bullet.state = {
        size: {
            h: 20,
            w: 20
        },
        position: {
            x: shooter.state.position.x,
            y: shooter.state.position.y
        },
        moveSpeed: 10,
        direction: shooter.state.lastDirection
    };

    // Set up any other constants
    var bulletSize = 5;

    // Draw the player on the canvas
    bullet.render = function() {

        // Draw circle
        scope.context.beginPath();
        scope.context.arc(bullet.state.position.x, bullet.state.position.y, bulletSize, 0, 2 * Math.PI, false);
        scope.context.fillStyle = 'green';
        scope.context.fill();
        scope.context.lineWidth = 2;
        scope.context.strokeStyle = '#003300';
        scope.context.stroke();
    };

    // Fired via the global update method.
    // Mutates state as needed for proper rendering next state
    bullet.update = function() {
        
        // Move based on last desition
        switch (bullet.state.direction)
        {
            case 'left': 
                bullet.state.position.x -= bullet.state.moveSpeed;
            break;
            case 'right':
                bullet.state.position.x += bullet.state.moveSpeed;
            break;
            case 'up':
                bullet.state.position.y -= bullet.state.moveSpeed;
            break;
            case 'down':
                bullet.state.position.y += bullet.state.moveSpeed;
            break;
        }

        // Find collision to destroyable entity and destroy it
        var collideEntity = scope.findCollision(bullet);
        if (collideEntity && collideEntity.destroy) {
            collideEntity.destroy();
            bullet.destroy();

            // Add score for shooted entity
            scope.state.score += 1;
        }

        // Destroy bullet if it is outsite canvas
        if (mathHelpers.EntityOutOfCanvas(scope, bullet)) {
            bullet.destroy();
        }
    };

    bullet.destroy = function() {
        scope.deleteEntity(bullet);
    };

    return bullet;
}

module.exports = Bullet;