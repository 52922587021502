var botEnt = require('../entities/bot.js');
var AI = require('../utils/utils.ai.js');

/** BotsMother Module
 * Main botsMother engine module.
 */
function BotsMother(scope) {
    var that = this;

    // Create the initial state
    that.state = {
        before: 0,
        bornBots: 0,
        secondsTillBot: 2
    };

    // Set up any other constants

    // Fired via the global update method.
    // Mutates state as needed for proper rendering next state
    that.update = function() {
        var now = performance.now();
        
        // Check if needed time elapsed
        if (now - that.state.before > 1000 * that.state.secondsTillBot) {
            
            // Save before to state
            that.state.before = now;

            // If it is night, new bot borns
            if (scope.state.nightMode) {

                // Do not create too many entities - becomes laggy
                if (Object.keys(scope.state.entities).length > 5000) {
                    return;
                }

                // Find coordinates
                var x = -50;
                var y = -50;
                var startingSide = AI.makeChoice(['left', 'right', 'up', 'down'])

                switch (startingSide) {
                    case 'right':
                        x = scope.viewport.width + 50;
                    case 'left':
                        y = AI.makeNumberChoice(0, scope.viewport.height);
                    break;
                    case 'down':
                        y = scope.viewport.height + 50;
                    case 'up':
                        x = AI.makeNumberChoice(0, scope.viewport.width);
                    break;
                }

                // Create a bot
                scope.state.entities["bot" + that.state.bornBots++] = new botEnt(scope, x, y);
            }
        }

    };

    return that;
}

module.exports = BotsMother;