/** btnDown Utility Module
 * Monitors and determines whether a button 
 * is pressed down at any given moment.
 * Returns object of true/false for each button with class .btn.
 */
function btnDown() {
    var self = { isPressed: {} };

    var buttons = document.getElementsByClassName('btn');

    function bindBtn(button) {
        var buttonName = button.dataset.btnName;
        button.onmousedown = function() {
            self.isPressed[buttonName] = true;
        };
        button.addEventListener('touchstart', function(ev) { 
            ev.preventDefault();
            self.isPressed[buttonName] = true;
        });
        button.onmouseup = function() {
            self.isPressed[buttonName] = false;
        };
        button.addEventListener('touchend', function(ev) { 
            ev.preventDefault();
            self.isPressed[buttonName] = false;
        });
        button.addEventListener('touchcancel', function(ev) { 
            ev.preventDefault();
            self.isPressed[buttonName] = false;
        });
    }

    for (var i = 0, buttonsCount = buttons.length; i < buttonsCount; i++) {
        bindBtn(buttons[i]);
    }

    return self;
}

module.exports = btnDown();