var gameLoop = require('./core/game.loop.js');
var gameUpdate = require('./core/game.update.js');
var gameRender = require('./core/game.render.js');

var playerEnt = require('./entities/player.js');
var clockEnt = require('./entities/clock.js');
var bulletEnt = require('./entities/bullet.js');
var gridEnt = require('./entities/grid.js');

var botsMotherEng = require('./engines/botsMother.js');

var cUtils = require('./utils/utils.canvas.js');
var mUtils = require('./utils/utils.math.js');

var $container = document.getElementById('gameContainer');

function Game () {
    var that = this;

    // Setup some constants
    this.constants = {
        targetFps: 60,
        showFps: true,
        showEntitiesCount: true
    };

    // Instantiate an empty state object
    this.state = {
        entities: {},
        engines: {},
        nightMode: false,
        currentDay: 0,
        playerLifes: 3,
        score: 0
    };

    // Generate a canvas and store it as our viewport
    this.viewport = $container;
    this.viewport.id = "gameViewport";

    // Get and store the canvas context as a global
    this.context = this.viewport.getContext('2d');

    // Make canvas full screen
    cUtils.resizeCanvas(this.viewport);

    // Main game functions
    this.update = gameUpdate(this);
    this.render = gameRender(this);
    this.loop = gameLoop(this);
    this.setNightMode = function(nightMode) {
        that.state.nightMode = nightMode;
        document.body.className = nightMode ? 'night' : 'day';
        if (!nightMode) {
            that.state.currentDay++;

            // Add score for passed day
            that.state.score += 5;
        } else {
            that.state.engines.botsMother.state.secondsTillBot = 2 / that.state.currentDay;
        }
    }
    this.findCollision = function(entity) {
        for (var eName in that.state.entities) {
            var e = that.state.entities[eName];
            if (e != entity && 
                mUtils.EntitiesColide(e, entity)) {
                return e;
            }
        }
        return null;
    }
    this.deleteEntity = function(entity) {
        for (var eName in that.state.entities) {
            var e = that.state.entities[eName];
            if (e === entity) {
                delete that.state.entities[eName];
                break;
            }
        }
    }
    var shotsCount = 0;
    this.shoot = function(shooter) {
        that.state.entities['bullet' + shotsCount++] = new bulletEnt(that, shooter);
    }

    // Get current canvas size
    var w = this.viewport.width,
        h = this.viewport.height;

    // Add grid
    //that.state.entities.grid = new gridEnt(that);

    // Add clock
    that.state.entities.clock = new clockEnt(that, w - 50, 50);

    // Create new player object
    that.state.entities.player = new playerEnt(that, (w / 2), (h - 100));

    // Create engines
    that.state.engines.botsMother = new botsMotherEng(that);

    return this;
}

// Instantiate a new game in the global scope
window.game = new Game();

module.exports = game;