/** 
 * Number.prototype.boundary
 * Binds a number between a minimum and a maximum amount.
 * var x = 12 * 3;
 * var y = x.boundary(3, 23);
 * y === 23
 */

var Boundary = function numberBoundary(min, max) {
    return Math.min(Math.max(this, min), max);
};

// Expose methods
Number.prototype.boundary = Boundary;
module.exports = {
    Boundary: Boundary,
    EntitiesColide: function(entityA, entityB) {
        return true &&
            entityA.state.position.x < entityB.state.position.x + entityB.state.size.w &&
            entityA.state.position.x + entityA.state.size.w > entityB.state.position.x &&
            entityA.state.position.y < entityB.state.position.y + entityB.state.size.h &&
            entityA.state.size.h + entityA.state.position.y > entityB.state.position.y;
    },
    EntityOutOfCanvas: function(scope, entity) {
        return entity.state.position.x < 0
            || entity.state.position.x > scope.viewport.width
            || entity.state.position.y < 0
            || entity.state.position.y > scope.viewport.height;
    }
};